import Service from '../Service';

const resource = "AssigCaliberPerson/"

export default {

    save(obj, requestID) {
        return Service.post(resource + 'save', obj, {
            params: { requestID: requestID }
        });
    },

    GetPermissions(PrsID, VrtID, requestID) {
        return Service.post(resource + "GetPermissions", "", {
            params: { PrsID: PrsID, VrtID: VrtID, requestID: requestID },
        });
    },

}