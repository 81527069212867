<template>
	<!-- <v-container> -->

	<!-- <div class="col-md-12 col-sm-12"> -->

	<v-card dense>
		<s-select-definition
			:dgr="groupID"
			:def="1266"
			@change="change($event)"
			hidden
		>
		</s-select-definition>
		<v-row style="margin: auto">
			<v-col cols="10">
				<b
					>Seleccione Calibres para asignar a:
					{{ itemAssistence.NtpNameFull }}</b
				>
			</v-col>
			<v-col cols="2">
				<v-btn :color="'info'" @click="close()">Close</v-btn>
			</v-col>
			<v-col cols="12" lg="3" md="3">
				<s-select-definition
					:def="1173"
					return-object
					label="Seleccione Cultivo"
					@input="inputCultive($event)"
					v-model="TypeCultive"
				>
				</s-select-definition>
				<b>Solo asignar Calibres al Cultivo que esta en proceso, no debe asignar calibres a 2 cultivos a la vez.</b>
			</v-col>
			<v-col cols="12" lg="12" md="12">
				<v-card>
					<v-card-text>
						<v-data-table
							v-model="selectedPermissions"
							:headers="headers"
							:items="items"
							:single-select="singleSelect"
							item-key="TypeCaliber"
							show-select
							class="elevation-1"
							@item-selected="itemSelected"
							:items-per-page="-1"
							hide-default-footer
							disable-sort
							disable-filtering
							:mobile-breakpoint="624"
						>
						</v-data-table>
					</v-card-text>

					<v-divider></v-divider>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
	<!-- </div> -->
	<!-- </v-container> -->
</template>

<script>
	import _sAssignCaliberService from '../../../services/FreshProduction/PrfAssignCaliberPersonService'
	export default {
		props: {
			itemAssistence: {},
		},
		components: {},

		data() {
			return {
				TypeCultive:{},
				items: [],
				headers: [{ text: "Calibre", value: "TypeCaliberName" }],
				singleSelect: false,
				selected: [],
				selectedPermissions: [],
				groupID: 0,
				VrtID: 0
				
				
			};
		},

		created() {
			//this.initialize();
		},
		methods: {

			inputCultive(item){

				console.log("item", item);
				if(item !== undefined){
					if(!isNaN(item.DedAbbreviation)){
						this.groupID = parseInt(item.DedAbbreviation)
						this.VrtID = parseInt(item.DedValue)
						console.log("cultivo",item);
						this.initialize()
					}else{
						this.items = []
					}
					
				}
				
			},
			change(val) {
				console.log('recibido ', val);
				this.items = val;

				this.items.forEach(element => {
					element.TypeCaliber = element.DedValue;
					element.TypeCaliberName = element.DedDescription;

					delete(element.DedAbbreviation)
					delete(element.DedHelper)
					delete(element.DedID)
					delete(element.DefID)
					delete(element.DgdID)
					delete(element.DgrID)
					delete(element.SecStatus)
					delete(element.DedValue)
					delete(element.DedDescription)
				});
				console.log("items", this.items);
			},

			close() {
				this.$emit("close");
			},

			initialize() {
				 _sAssignCaliberService
					.GetPermissions(this.itemAssistence.PrsID, this.VrtID, this.$fun.getUserID())
					.then((resp) => {
					this.selectedPermissions = resp.data;
					console.log("selectedPermissions", this.selectedPermissions);
				});
			},

			itemSelected(item) {

					var AcpID = 0;
					var obj = this.selectedPermissions.find((x) => x.TypeCaliber == item.item.TypeCaliber);
					
					if(obj === undefined) {
						AcpID = 0;
					}else{
						AcpID = obj.AcpID;
					}
					
					console.log("Caliber: ", item.item.TypeCaliber, "AcpID", AcpID);
				if (item.value) {
                    
					//this.itemAssistence.AcpID ? this.selectedPermissions.filter((x) => x.TypeCaliber = item.item.TypeCaliber)[0].AcpID : 0;
                    this.itemAssistence.TypeCaliber = item.item.TypeCaliber;
					this.itemAssistence.SecStatus = 1;
					this.itemAssistence.UsrCreateID = this.$fun.getUserID();
					this.itemAssistence.AcpID = AcpID
					this.itemAssistence.VrtID = this.VrtID

				} else {
					
					this.itemAssistence.TypeCaliber = item.item.TypeCaliber;
					this.itemAssistence.AcpID = AcpID;

					
					this.itemAssistence.SecStatus = 0;
					this.itemAssistence.UsrCreateID = this.$fun.getUserID();
				}

				
				//samir
				this.itemAssistence.TypeCultive = this.TypeCultive.DedValue;
				
				console.log("this.TypeCultive samir frescos", this.itemAssistence);
				//return;

				_sAssignCaliberService
				.save(this.itemAssistence, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200) {
						this.$fun.alert("Guardado Correctamente", "success");
						this.initialize();
					}
					
				}); 
			},
		},
	};
</script>
