<template>
	<v-container>
		<div>
			<v-row>
				<v-col cols="12" lg="12" md="12">
					<s-crud
						:config="configTurn"
						:filter="filter" title="Turno"
						no-search
						ref="gridTurn"
						no-full
						@rowSelected="rowSelected($event)"
						height="100px"
						:mobile="1024"
					>
						<template v-slot:filter>
							<v-col >
								<v-row justify="center">
									<v-col lg="8">
										<!-- <s-select-definition
											v-model="filter.TypeAre"
											:def="1281"
											label="Area"
											@input="inputFilter($event)"
										>
										</s-select-definition> -->
										<s-turn-in-process :itemvalue="'TypeArea'" :TypeArea="5" v-model="filter.TypeAre" @input="inputFilter($event)"></s-turn-in-process>
									</v-col>
									
								</v-row>
							</v-col>
						</template>

						<template v-slot:TrnStatus="{ row }">
							<v-chip
								x-small
								:color="row.TrnStatus == 1 ? 'error' : 'default'"
								:height="25"
								rounded
								>
								{{ row.TrnStatus == 1 ? "En Proceso" : "Terminado" }}
							</v-chip>
						</template>

					</s-crud>
				</v-col>

				<v-col cols="12" lg="12" md="12" v-if="filter.TypeAre == 5 || filter.TypeAre == 6">
					<s-crud
						:config="configAssistence"
						:filter="filterAssistence"
						search-input
						ref="gridAssistenceP"
						:mobile="1024"
						@rowSelected="rowSelectedAssistence($event)"
					>
						<template v-slot:filter v-if="this.selected.TrnStatus == 1 && itemAssistence !== undefined">
							<v-row style="margin:auto">
								<v-col lg="3" cols="4">
									<v-btn
										:color="'info'"
										style="margin-bottom: 10px; margin-left: 10px"
										:disabled="disabled"
										@click="openModal()"
									>
										Permisos
									</v-btn>
								</v-col>
								<v-col
										cols="8"
										lg="6"
										md="6"
										
									>
										<s-select-activity-area
											clearable
											label="Actividad"
											:typeArea="filter.TypeAre"
											v-model="filterAssistence.AtaID"
										></s-select-activity-area>
									</v-col>
							</v-row>
						</template>
					</s-crud>
				</v-col>
			</v-row>
		</div>

		<v-dialog v-if="openDialog" v-model="openDialog" hide-overlay persistent>
			<assign-caliber-assistant-new
				:itemAssistence="itemAssistence"
				@close="close()"
			>
			</assign-caliber-assistant-new>
		</v-dialog>
	</v-container>
</template>
<script>
	//Service
	import _sTurnService from "../../../services/FreshProduction/PrfTurnService";
	import _sTurnWorKerervice from "../../../services/FreshProduction/PrfAssignTurnWorKerDetailService";
	import AssignCaliberAssistantNew from "./AssignCaliberAssistantNew.vue";
	import sSelectActivityArea from "../../../components/FreshProduction/Turn/sSelectActivityArea.vue";
	import sTurnInProcess from "@/components/FreshProduction/Turn/sTurnInProcess.vue"

	export default {
		components: { sTurnInProcess, AssignCaliberAssistantNew, sSelectActivityArea },

		data: () => ({
			filter: { TypeAre: 0, TypeShip: 1 },
			filterAssistence: { TrnID: 0, AtaID: null },

			item: {},
			selected: {},
			itemAssistence: null,
			disabled: true,
			openDialog: false,
			configTurn: {
				model: {
						TrnID: "ID",
						TrnStatus:"",
				},
				service: _sTurnService,
				headers: [
					{
						text: "Acciones",
						value: "TrnStatus",
						sortable: false,
						width: 70,
					},
					{ text: "Turno", value: "TypeTurnName", sortable: false },
				],
			},

			configAssistence:{
				model: {
					AgdID: "ID",
				},
				service: _sTurnWorKerervice,
				headers: [
					{ text: "Doc. Identidad", value: "PrsDocumentNumber", sortable: false, width:"50" },
					{ text: "Persona", value: "NtpNameFull", sortable: false, width:"300" },
					{ text: "Actividad", value: "AtaName", sortable: false, width:"100" },
					
				],
			},
		}),

		watch: {},

		methods: {
			close() {
				this.openDialog = false;
			},
			rowSelected(item) {
				console.log("rowSelected", item[0]);

				if (item[0] == undefined) {
					this.disabled = true;
				} else {
					this.disabled = false;
				}

				if (item.length > 0) {
					this.selected = item[0];
					this.filterAssistence.TrnID = item[0].TrnID;
					this.filterAssistence.AtaID = null;
				}

			},

			rowSelectedAssistence(val) {
				if (val !== undefined) {
					this.itemAssistence = val[0];
					console.log("dddddddddd",this.itemAssistence);
				}
			},

			inputFilter(val) {
				if (val !== 0) {
					this.filterAssistence.TrnID = 0;
					this.filterAssistence.AtaID = null;
				}
			},

			openModal() {
				this.openDialog = true;
			},
		},
	};
</script>
